import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rating from "react-rating-stars-component"; // For star rating component
import Sidebar from "../../components/common/Sidebar"; // Replace with your Sidebar component
import DataTable from "../../components/common/DataTable"; // Replace with your DataTable component
import { apiRequest } from "../../hooks/apiRequest"; // Replace with your API hook
import { STU_REVIEW_ENDPOINT, FILE_UPLOAD_ENDPOINT } from "../../api/endpoints"; // Replace with your API endpoint
import { useNavigate } from "react-router-dom";
import BookSpinner from "../../components/common/BookSpinner";
import directusClient from "../../api/directusClient";

const StuReviewMgt = () => {
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("access_token");
        directusClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        const response = await directusClient.get("/users/me");
      } catch (err) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("email");

        delete directusClient.defaults.headers.common["Authorization"];
        window.location.href = "/";
      }
    };
    fetchUserProfile();
  }, []);

  const navigate = useNavigate();
  const [studentReviews, setStudentReviews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentReview, setCurrentReview] = useState({});
  const [loading, setLoading] = useState(true);
  const [newReview, setNewReview] = useState({
    name: "",
    review_comment: "",
    rate_star: 0,
    stu_image: "", // Add stu_image to the review state
  });
  const [imageFile, setImageFile] = useState(null); // To store the selected image file
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const userRole = localStorage.getItem("user_role") || "";

  const [showConfirmModal, setShowConfirmModal] = useState(false); // New state for confirmation modal
  const [confirmationCallback, setConfirmationCallback] = useState(null); // Store callback for confirmation
  const [submitMessage, setSubmitMessage] = useState("");

  const showConfirmation = (message, callback) => {
    setSubmitMessage(message);
    setConfirmationCallback(() => callback);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    if (confirmationCallback) confirmationCallback();
    setShowConfirmModal(false);
  };

  const handleCancelConfirm = () => {
    setShowConfirmModal(false);
  };

  // Fetch student reviews
  useEffect(() => {
    const fetchStudentReviews = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(STU_REVIEW_ENDPOINT);
        setStudentReviews(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to load student review data.");
        setLoading(false);
      }
    };
    fetchStudentReviews();
  }, []);

  // Handle file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const uploadFile = async (file) => {
    const url = `${process.env.REACT_APP_DIRECTUS_URL}${FILE_UPLOAD_ENDPOINT}`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const jsonResponse = await response.json();
      const id = jsonResponse.data.id;
      setImageFile(null);
      return id;
    } catch (error) {
      console.error("Error uploading file:", error);
      return "";
    }
  };

  // Handle adding a new student review
  const handleAdd = async () => {
    showConfirmation("Are you sure you want to add this?", async () => {
      try {
        setLoading(true);
        let imageId = "";
        if (imageFile) {
          imageId = await uploadFile(imageFile);
        }
        // const uploadedImageUrl = await uploadFile();
        if (imageId == "") {
          toast.error("Failed to add image");
          setLoading(false);
          return;
        } // Stop if image upload failed

        const reviewData = { ...newReview, stu_image: imageId };
        console.log("reviewData", reviewData);
        const response = await apiRequest(
          STU_REVIEW_ENDPOINT,
          "POST",
          reviewData
        );
        setStudentReviews([...studentReviews, response.data]);
        setShowAddModal(false);
        setLoading(false);
        toast.success("Student review added successfully.");
      } catch (error) {
        setLoading(false);
        toast.error("Failed to add student review.");
      }
    });
  };
  // Handle deleting a student review
  const handleDelete = async (id) => {
    showConfirmation("Are you sure you want to delete this?", async () => {
      try {
        setLoading(true);
        await apiRequest(`${STU_REVIEW_ENDPOINT}/${id}`, "DELETE");
        setStudentReviews(studentReviews.filter((review) => review.id !== id));
        toast.success("Student review deleted successfully.");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to delete student review.");
      }
    });
  };
  // Handle editing a student review
  const handleEdit = (review) => {
    setCurrentReview(review);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    showConfirmation("Are you sure you want to edit this?", async () => {
      try {
        setLoading(true);
        let imageId = "";
        if (imageFile) {
          console.log("Uploading file:", imageFile);
          imageId = await uploadFile(imageFile); // Upload the image
        }

        // Prepare the updated review data
        const updatedReview = { ...currentReview };
        if (imageId !== "") {
          updatedReview.stu_image = imageId; // Update with the new image ID
        }

        // Send the updated review to the API
        await apiRequest(
          `${STU_REVIEW_ENDPOINT}/${currentReview.id}`,
          "PATCH",
          updatedReview
        );
        setStudentReviews(
          studentReviews.map((review) =>
            review.id === currentReview.id ? updatedReview : review
          )
        );
        setShowModal(false);
        setLoading(false);
        toast.success("Student review updated successfully.");
      } catch (error) {
        setLoading(false);
        console.error("Error updating review:", error);
        toast.error("Failed to update student review.");
      }
    });
  };

  // DataTable columns
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Student Name", accessor: "name" },
    {
      Header: "Review Text",
      accessor: "review_comment",
      Cell: ({ row }) => {
        const reviewText = row.original.review_comment;
        const truncatedReview =
          reviewText.length > 50 ? reviewText.slice(0, 50) + "..." : reviewText;
        return <span>{truncatedReview}</span>;
      },
    },
    // {
    //   Header: "Rating",
    //   accessor: "rate_star",
    //   Cell: ({ row }) => (
    //     <Rating
    //       count={5}
    //       value={parseInt(row.original.rate_star)}
    //       size={20}
    //       edit={false} // Set edit to false to make the rating non-editable
    //       activeColor="#ffd700"
    //     />
    //   ),
    // },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <Button
            variant="primary"
            className="m-1"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            className="m-1"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  if (loading) return <BookSpinner />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="p-3 d-none d-md-block">
            <Sidebar role={userRole} />
          </Col>
          <Col md={10} className="p-3">
            <Card className="p-3">
              <div className="d-flex justify-content-between align-items-center">
                <h1>REVIEWS</h1>
                <button
                  type="button"
                  className="navbar-toggler bg-light"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </Card>

            <Card className="mt-3">
              <h2 className="text-center m-3">Manage Student Reviews</h2>
              <h4 className="text-center m-3">
                THE REVIEW TEXT SHOULD BE BETWEEN 40 AND 50 WORDS.
              </h4>
              <Button
                variant="secondary"
                className="m-3"
                onClick={() => setShowAddModal(true)}
              >
                Add New Review +
              </Button>
              <DataTable columns={columns} data={studentReviews} />
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Add Review Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={newReview.name}
                onChange={(e) =>
                  setNewReview({ ...newReview, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Review Comment</Form.Label>
              <Form.Control
                as="textarea"
                value={newReview.review_comment}
                onChange={(e) =>
                  setNewReview({ ...newReview, review_comment: e.target.value })
                }
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Rating</Form.Label>
              <Rating
                count={5}
                value={parseInt(newReview.rate_star)}
                onChange={(value) =>
                   setNewReview({ ...newReview, rate_star: value })
                }
                size={30}
                activeColor="#ffd700"
              /> 
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button variant="primary" className="mt-2" onClick={handleAdd}>
              Add Review
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Review Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={currentReview.name || ""}
                onChange={(e) =>
                  setCurrentReview({ ...currentReview, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Review Comment</Form.Label>
              <Form.Control
                as="textarea"
                value={currentReview.review_comment || ""}
                onChange={(e) =>
                  setCurrentReview({
                    ...currentReview,
                    review_comment: e.target.value,
                  })
                }
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Rating</Form.Label>
              <Rating
                count={5}
                value={parseInt(currentReview.rate_star) || 0}
                onChange={(value) =>
                  setCurrentReview({ ...currentReview, rate_star: value })
                }
                size={30}
                activeColor="#ffd700"
              />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Upload New Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button
              variant="primary"
              className="mt-2"
              onClick={handleEditSubmit}
            >
              Update Review
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Toast container */}
      <ToastContainer />

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{submitMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StuReviewMgt;
