import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { apiRequest } from "../../hooks/apiRequest";
import {
  COURSE_MATERIAL_ENDPOINT,
  COURSE_MATERIAL_SUB_TOPIC_ENDPOINT,
  FILE_UPLOAD_ENDPOINT,
} from "../../api/endpoints";
import { FaFileAlt, FaFilePdf, FaYoutube } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/common/Sidebar";
import { useNavigate } from "react-router-dom";
import BookSpinner from "../../components/common/BookSpinner";
import directusClient from "../../api/directusClient";

const CourseMaterialsMgt = () => {
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("access_token");
        directusClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        const response = await directusClient.get('/users/me');
      } catch (err) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("email");

        delete directusClient.defaults.headers.common["Authorization"];
        window.location.href = "/";
      }
    };
    fetchUserProfile();
  }, []);
  const navigate = useNavigate();
  const [courseMaterials, setCourseMaterials] = useState([]);
  const [showSubTopicModal, setShowSubTopicModal] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [isEditingSubtopic, setIsEditingSubtopic] = useState(false);
  const [isEditingTopic, setIsEditingTopic] = useState(false);
  const [currentTopicId, setCurrentTopicId] = useState(null);
  const [currentSubtopicId, setCurrentSubtopicId] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // New state for confirmation modal
  const [confirmationCallback, setConfirmationCallback] = useState(null); // Store callback for confirmation
  const [submitMessage, setSubmitMessage] = useState("");

  const showConfirmation = (message, callback) => {
    setSubmitMessage(message);
    setConfirmationCallback(() => callback);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    if (confirmationCallback) confirmationCallback();
    setShowConfirmModal(false);
  };

  const handleCancelConfirm = () => {
    setShowConfirmModal(false);
  };

  // Close video modal
  const closeVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideo("");
  };

  const [formData, setFormData] = useState({
    topic: "", // Only for editing the topic name
    subtopic: {
      title: "",
      doc_url: "",
      pdf_url: "",
      video_url: "",
      login_need: false,
    },
  });
  const userRole = localStorage.getItem("user_role") || "";
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [fileUploads, setFileUploads] = useState({
    docFile: null,
    pdfFile: null,
  });
  const uploadFile = async (file) => {
    const url = `${process.env.REACT_APP_DIRECTUS_URL}${FILE_UPLOAD_ENDPOINT}`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const jsonResponse = await response.json();
      const id = jsonResponse.data.id;
      return id;
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed.");
      return null;
    }
  };

  const handleVideoClick = (link) => {
    console.log(link);
    setCurrentVideo(link);
    setShowVideoModal(true);
  };

  // Fetch initial data for topics and their subtopics
  useEffect(() => {
    const fetchCourseMaterials = async () => {
      try {
        setLoading(true);
        const response = await apiRequest(COURSE_MATERIAL_ENDPOINT);
        const courseMaterialsWithSubtopics = await Promise.all(
          response.data.map(async (course) => {
            // Fetch subtopics for each topic
            const subtopicsResponse = await apiRequest(
              `${COURSE_MATERIAL_SUB_TOPIC_ENDPOINT}?filter[topic_id][_eq]=${course.id}`
            );
            return { ...course, subtopics: subtopicsResponse.data };
          })
        );
        setCourseMaterials(courseMaterialsWithSubtopics);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to load course materials.");
        setLoading(false);
      }
    };
    fetchCourseMaterials();
  }, []);

  // Handle saving or editing a topic
  const handleSaveTopic = async () => {
    showConfirmation("Are you sure you want to do this?", async () => {
      try {
        setLoading(true);
        if (!formData.topic) {
          toast.error("Topic name is required.");
          setLoading(false);
          return;
        }

        let response;
        if (currentTopicId) {
          // Editing an existing topic
          const updateData = { topic: formData.topic };
          console.log(updateData);
          response = await apiRequest(
            `${COURSE_MATERIAL_ENDPOINT}/${currentTopicId}`,
            "PATCH",
            updateData
          );

          // Update the courseMaterials state with the updated topic
          setCourseMaterials((prev) =>
            prev.map((course) =>
              course.id === currentTopicId
                ? { ...course, topic: response.data.topic }
                : course
            )
          );
          setCurrentTopicId(null);
          setLoading(false);
          toast.success("Topic updated successfully.");
        } else {
          // Adding a new topic
          response = await apiRequest(COURSE_MATERIAL_ENDPOINT, "POST", {
            topic: formData.topic,
          });
          const newCourseMaterial = { ...response.data, subtopics: [] };
          setCourseMaterials([...courseMaterials, newCourseMaterial]);
          toast.success("Topic added successfully.");
        }

        // Reset form data and close modal
        setFormData({
          topic: "",
          subtopic: {
            title: "",
            doc_url: "",
            pdf_url: "",
            video_url: "",
            login_need: false,
          },
        });
        setShowTopicModal(false);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to save topic.");
        setLoading(false);
      }
    });
  };

  const handleSaveSubtopic = async () => {
    showConfirmation("Are you sure you want to do this?", async () => {
    try {
      setLoading(true);
      if (!currentTopicId) {
        toast.error("Select a topic to add subtopics.");
        setLoading(false);
        return;
      }

      const docFileId = fileUploads.docFile
        ? await uploadFile(fileUploads.docFile)
        : formData.subtopic.doc_url;
      const pdfFileId = fileUploads.pdfFile
        ? await uploadFile(fileUploads.pdfFile)
        : formData.subtopic.pdf_url;

      if (!docFileId || !pdfFileId) {
        toast.error("File upload failed.");
        setLoading(false);
        return;
      }

      const subtopicData = {
        ...formData.subtopic,
        topic_id: currentTopicId,
        doc_url: docFileId,
        pdf_url: pdfFileId,
      };

      let response;
      if (isEditingSubtopic) {
        response = await apiRequest(
          `${COURSE_MATERIAL_SUB_TOPIC_ENDPOINT}/${currentSubtopicId}`,
          "PATCH",
          subtopicData
        );

        setCourseMaterials((prev) =>
          prev.map((course) =>
            course.id === currentTopicId
              ? {
                  ...course,
                  subtopics: course.subtopics.map((sub) =>
                    sub.id === currentSubtopicId ? response.data : sub
                  ),
                }
              : course
          )
        );
        toast.success("Subtopic updated successfully.");
        setLoading(false);
      } else {
        response = await apiRequest(
          COURSE_MATERIAL_SUB_TOPIC_ENDPOINT,
          "POST",
          subtopicData
        );
        setCourseMaterials((prev) =>
          prev.map((course) =>
            course.id === currentTopicId
              ? { ...course, subtopics: [...course.subtopics, response.data] }
              : course
          )
        );
        toast.success("Subtopic added successfully.");
      }
      setLoading(false);

      setFormData({
        topic: "",
        subtopic: {
          title: "",
          doc_url: "",
          pdf_url: "",
          video_url: "",
          login_need: false,
        },
      });
      setFileUploads({ docFile: null, pdfFile: null });
      setShowSubTopicModal(false);
      setIsEditingSubtopic(false);
    } catch (error) {
      toast.error("Failed to save subtopic.");
    }
  });
  };

  // Handle deleting a subtopic
  const handleDeleteSubtopic = async (subtopicId, topicId) => {
    showConfirmation("Are you sure you want to delete this?", async () => {
    try {
      setLoading(true);
      await apiRequest(
        `${COURSE_MATERIAL_SUB_TOPIC_ENDPOINT}/${subtopicId}`,
        "DELETE"
      );
      setCourseMaterials((prev) =>
        prev.map((course) =>
          course.id === topicId
            ? {
                ...course,
                subtopics: course.subtopics.filter(
                  (sub) => sub.id !== subtopicId
                ),
              }
            : course
        )
      );
      toast.success("Subtopic deleted successfully.");
      setLoading(false);
    } catch (error) {
      toast.error("Failed to delete subtopic.");
      setLoading(false);
    }
  });
  };

  // Set form data to edit a subtopic
  const handleEditSubtopic = (subtopic) => {
    setCurrentVideo("");
    setFormData({
      topic: "", // Topic name remains unchanged for subtopic edit
      subtopic: {
        title: subtopic.title,
        doc_url: subtopic.doc_url,
        pdf_url: subtopic.pdf_url,
        video_url: subtopic.video_url,
        login_need: subtopic.login_need, // ensure checkbox state
      },
    });
    setIsEditingSubtopic(true);
    setCurrentSubtopicId(subtopic.id);
    setCurrentTopicId(subtopic.topic_id);
    setShowSubTopicModal(true);
  };

  // Set form data to edit a subtopic
  const handleEditTopic = (course) => {
    console.log(course);
    setFormData({
      topic: course.topic, // Topic name remains unchanged for subtopic edit
      subtopic: {
        title: "",
        doc_url: "",
        pdf_url: "",
        video_url: "",
        login_need: false, // ensure checkbox state
      },
    });
    setIsEditingTopic(true);
    setCurrentTopicId(course.id);
    setShowTopicModal(true);
  };

  if (loading) return <BookSpinner />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="p-3 d-none d-md-block">
            <Sidebar role={userRole} />
          </Col>
          <Col
            md={2}
            className={`p-3 d-md-none ${sidebarOpen ? "d-block" : "d-none"}`}
          >
            <Sidebar role={userRole} />
          </Col>
          <Col md={10} className="p-3">
            <Card className="p-3 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="font-weight-bold">COURSE MATERIALS</h1>
                <Button
                  variant="light"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  className="navbar-toggler bg-light"
                >
                  <span className="navbar-toggler-icon"></span>
                </Button>
              </div>
            </Card>

            <Card className="text-center mt-3">
              <h2 className="m-3">Course Materials Management</h2>
              <Button
              style={{
                backgroundColor: "blue",
                borderColor: "blue",
                color: "black",
                fontWeight: "bold",
              }}
                variant="primary"
                className="m-3"
                onClick={() => {
                  setCurrentTopicId(null);
                  setShowTopicModal(true);
                  setIsEditingTopic(false);
                  setFormData({
                    topic: "",
                    subtopic: {
                      title: "",
                      doc_url: "",
                      pdf_url: "",
                      video_url: "",
                      login_need: false,
                    },
                  });
                }}
              >
                Add New Topic +
              </Button>

              {courseMaterials.map((course) => (
                <Card key={course.id} className="mb-3">
                  <Card.Header className="bg-secondary text-white">
                    <h5>{course.topic}</h5>
                    <Button
                      className="m-1"
                      style={{background : "#004380", borderColor : "#004380"}}
                      onClick={() => handleEditTopic(course)}
                    >
                      EDIT TOPIC
                    </Button>
                    <Button
                      style={{background : "#004380", borderColor : "#004380"}}
                      className="m-1"
                      onClick={() => {
                        setFormData({
                          topic: "",
                          subtopic: {
                            title: "",
                            doc_url: "",
                            pdf_url: "",
                            video_url: "",
                            login_need: false,
                          },
                        });
                        setCurrentVideo("");
                        setCurrentTopicId(course.id);
                        setShowSubTopicModal(true);
                        setIsEditingSubtopic(false); // Clear form for adding a new subtopic
                      }}
                    >
                      Add Subtopic
                    </Button>
                  </Card.Header>
                  <Card.Body>
                    {course.subtopics.length > 0 ? (
                      <div className="subtopics-container">
                        {course.subtopics.map((subtopic) => (
                          <div
                            key={subtopic.id}
                            className="subtopic-row d-flex align-items-left justify-content-between"
                          >
                            <span className="subtopic-title flex-grow-1">
                              {subtopic.title}
                            </span>
                            <Button
                              variant="warning"
                              className="m-1"
                              onClick={() => handleEditSubtopic(subtopic)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              className="m-1"
                              onClick={() =>
                                handleDeleteSubtopic(subtopic.id, course.id)
                              }
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No subtopics available.</p>
                    )}
                  </Card.Body>
                </Card>
              ))}
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal for adding/editing */}
      <Modal
        show={showSubTopicModal}
        onHide={() => setShowSubTopicModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingSubtopic ? "Edit Subtopic" : "Add Subtopic"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Subtopic Title</Form.Label>
              <Form.Control
                type="text"
                value={formData.subtopic.title}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    subtopic: { ...formData.subtopic, title: e.target.value },
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Video URL</Form.Label>
              <Form.Control
                type="text"
                value={formData.subtopic.video_url}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    subtopic: {
                      ...formData.subtopic,
                      video_url: e.target.value,
                    },
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Doc File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  setFileUploads({ ...fileUploads, docFile: e.target.files[0] })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>PDF File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  setFileUploads({ ...fileUploads, pdfFile: e.target.files[0] })
                }
              />
            </Form.Group>
            <div
              className="icons"
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "15px",
                padding: "10px",
                alignItems: "center",
              }}
            >
              {formData.subtopic.doc_url && (
                <a
                  href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${formData.subtopic.doc_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }} // Optional to remove underline from links
                >
                  <FaFileAlt
                    className="icon"
                    color="blue"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.2)")
                    } // Hover effect
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    } // Reset hover effect
                  />
                </a>
              )}
              {formData.subtopic.pdf_url && (
                <a
                  href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${formData.subtopic.pdf_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <FaFilePdf
                    className="icon"
                    color="orange"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.2)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    }
                  />
                </a>
              )}
              {formData.subtopic.video_url && (
                <span
                  onClick={() => handleVideoClick(formData.subtopic.video_url)}
                  style={{ cursor: "pointer" }}
                >
                  <FaYoutube
                    className="icon"
                    color="red"
                    style={{
                      fontSize: "24px",
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.2)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    }
                  />
                </span>
              )}
            </div>

            {/* Video Modal */}
            {showVideoModal && (
              <div className="video-modal" onClick={closeVideoModal}>
                <div
                  className="video-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <iframe
                    src={currentVideo}
                    title="YouTube Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            )}

            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Login Needed"
                checked={formData.subtopic.login_need}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    subtopic: {
                      ...formData.subtopic,
                      login_need: !formData.subtopic.login_need,
                    },
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSubTopicModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveSubtopic}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for adding/editing */}
      <Modal show={showTopicModal} onHide={() => setShowTopicModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingTopic ? "Edit Topic" : "Add Topic"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Topic Title</Form.Label>
              <Form.Control
                type="text"
                value={formData.topic}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    topic: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTopicModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveTopic}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer position="top-right" autoClose={3000} />

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{submitMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CourseMaterialsMgt;
