// import React, { useRef } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { carouselData } from "../data/Data";
// import { FaPlay } from "react-icons/fa";
// export default function Carousel() {
//   const sliderRef = useRef(null);

//   const next = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickNext();
//     }
//   };

//   const previous = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickPrev();
//     }
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };
//   return (
//     <>
//       <div className="container-fluid p-0 mb-5">
//         <div
//           id="header-carousel"
//           className="carousel slide"
//           data-bs-ride="carousel"
//         >
//           <div className="carousel-inner mt-5">
//             <Slider ref={sliderRef} {...settings} >
//               {carouselData.map((val, index) => (
//                 <div className="carousel-item" key={index}>
//                   <img className="w-100" src={val.img} alt="Image" />
//                   <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
//                     <div className="p-3" style={{ maxWidth: "900px" }}>
//                       <h1 className="display-3 text-white mb-4 animated slideInDown">
//                         {val.title}
//                       </h1>
//                       <p className="section-title text-white text-uppercase mb-3 animated slideInDown">
//                         {val.subtitle}
//                       </p>
//                       <a
//                         href="/register"
//                         className="btn btn-primary rounded-pill py-md-2 px-md-5 me-3 animated slideInLeft w-70"
//                       >
//                         {val.btn1}
//                       </a>
//                       <a
//                         href="/contact"
//                         className="btn rounded-pill py-md-2 px-md-5 me-3 animated slideInLeft m-1 w-70"
//                         style={{background: "var(--bs-gray)" , color:"var(--bs-dark)"}}
//                       >
//                          {/* <FaPlay className="play-icon" /> */}
//                         {val.btn2}
//                       </a>              
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//           <button
//             className="carousel-control-prev"
//             type="button"
//             onClick={previous}
//           >
//             <span
//               className="carousel-control-prev-icon"
//               aria-hidden="true"
//             ></span>
//             <span className="visually-hidden">Previous</span>
//           </button>
//           <button
//             className="carousel-control-next"
//             type="button"
//             onClick={next}
//           >
//             <span
//               className="carousel-control-next-icon"
//               aria-hidden="true"
//             ></span>
//             <span className="visually-hidden">Next</span>
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselData } from "../data/Data";
import { FaPlay } from "react-icons/fa";

export default function Carousel() {
  const sliderRef = useRef(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner" style={{marginTop : "60px"}}>
            <Slider ref={sliderRef} {...settings}>
              {carouselData.map((val, index) => (
                <div className="carousel-item" key={index}>
                  <img className="w-100" src={val.img} alt="Image" />
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{ maxWidth: "900px" }}>
                      <h1 className="display-3 text-white mb-4 animated slideInDown">
                        {val.title}
                      </h1>
                      <p className="section-title text-white text-uppercase mb-3 animated slideInDown">
                        {val.subtitle}
                      </p>
                      <div className="d-flex flex-column flex-md-row w-100 justify-content-center" style={{alignItems :"center"}}>
                        <a
                          href="/register" style={{ maxWidth: "250px" }}
                          className="btn btn-primary rounded-pill py-md-2 px-md-3 me-md-3 mb-3 mb-md-0 w-100 w-md-auto animated slideInLeft"
                        >
                          {val.btn1}
                        </a>
                        <a
                          href="/contact"
                          className="btn rounded-pill py-md-2 px-md-3 w-100 w-md-auto animated slideInLeft"
                          style={{ background: "var(--bs-gray)", color: "var(--bs-dark)" , maxWidth: "250px" }}
                        >
                          {/* <FaPlay className="play-icon" /> */}
                          {val.btn2}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <button className="carousel-control-prev" type="button" onClick={previous}>
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" onClick={next}>
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
