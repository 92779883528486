import React, { useEffect } from "react";
import Heading from "../components/common/Heading";
import Blogs from "../components/home/Blogs";
import Sliders from "../components/home/Slider";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

export default function BlogsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Heading
        heading="Blogs"
        title="Home"
        subtitle="Blogs"
        description={
          "Explore quick study and revision tips, practical advice, and myth-busting articles designed to support students and parents alike. From handy tricks of the trade to insights gained as a parent, our blog is your go-to resource for guiding your child’s learning journey with confidence and success."
        }
        img={"/assets/img/ourBlogsBg.jpg"}
      />
      <Blogs />
      <Sliders />
      <Footer />
    </>
  );
}
