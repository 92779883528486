import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Spinner,
} from "react-bootstrap";
import { apiRequest } from "../../hooks/apiRequest"; // Hook for API request
import { MAIL_INQUIRY_ENDPOINT } from "../../api/endpoints"; // API endpoint for mail inquiries
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/common/Sidebar";
import DataTable from "../../components/common/DataTable";
import { useNavigate } from "react-router-dom";
import BookSpinner from "../../components/common/BookSpinner";
import directusClient from "../../api/directusClient";

const MailInquiresMgt = () => {
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("access_token");
        directusClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        const response = await directusClient.get('/users/me');
      } catch (err) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("email");

        delete directusClient.defaults.headers.common["Authorization"];
        window.location.href = "/";
      }
    };
    fetchUserProfile();
  }, []);
  const navigate = useNavigate();
  const [mailInquiries, setMailInquiries] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal for editing inquiry
  const [showAddModal, setShowAddModal] = useState(false); // Modal for adding new inquiry
  const [currentInquiry, setCurrentInquiry] = useState({});
  const [loading, setLoading] = useState(true);
  const [newInquiry, setNewInquiry] = useState({
    email: "",
    message: "",
    name: "",
    phone_number: "",
  });
  const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar visibility state
  const userRole = localStorage.getItem("user_role") || "";

  // Fetch mail inquiries from the API
  useEffect(() => {
    const fetchMailInquiries = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(MAIL_INQUIRY_ENDPOINT);
        setMailInquiries(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to load mail inquiry data.");
        setLoading(false);
      }
    };
    fetchMailInquiries();
  }, []);

  // Edit mail inquiry details
  const handleEdit = (inquiry) => {
    setCurrentInquiry(inquiry);
    setShowModal(true);
  };

  // Handle adding a new mail inquiry
  const handleAdd = async () => {
    try {
      setLoading(true);
      const response = await apiRequest(
        MAIL_INQUIRY_ENDPOINT,
        "POST",
        newInquiry
      );
      setMailInquiries([...mailInquiries, response.data]); // Add new inquiry to the list
      setShowAddModal(false);
      setLoading(false);
      toast.success("Mail Inquiry added successfully.");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add mail inquiry.");
    }
  };

  // Handle closing the modal
  const handleClose = () => setShowModal(false);
  const handleAddClose = () => setShowAddModal(false);

  // Handle changes in the new inquiry form
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setNewInquiry({ ...newInquiry, [name]: value });
  };

  // Handle saving changes after editing a mail inquiry
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await apiRequest(
        `${MAIL_INQUIRY_ENDPOINT}/${currentInquiry.id}`,
        "PATCH",
        currentInquiry
      );
      setMailInquiries(
        mailInquiries.map((inq) =>
          inq.id === currentInquiry.id ? currentInquiry : inq
        )
      );
      setLoading(false);
      setShowModal(false);
      toast.success("Mail Inquiry details updated successfully.");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update mail inquiry.");
    }
  };

  // Columns for DataTable
  const columns = [
    {
      Header: "Requested Date",
      accessor: "date_created",
      Cell: ({ value }) => {
        const formattedDate = value.replace('T', ' ').replace('.000Z', '');
        return formattedDate;
      },
    },
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone Number", accessor: "phone_number" },
    { Header: "Message", accessor: "message" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Button variant="primary" onClick={() => handleEdit(row.original)}>
          View
        </Button>
      ),
    },
  ];

  if (loading) return <BookSpinner />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="p-3 d-none d-md-block">
            <Sidebar role={userRole} />
          </Col>
          <Col
            md={2}
            className={`p-3 d-md-none ${sidebarOpen ? "d-block" : "d-none"}`}
          >
            <Sidebar role={userRole} />
          </Col>
          <Col md={10} className="p-3">
            <Card className="p-3">
              <div className="d-flex justify-content-between align-items-center">
                <h1>INQUIRY</h1>
                <button
                  type="button"
                  className="navbar-toggler bg-light"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </Card>

            <Card className="text-center mt-3">
              <h2 className="m-3">Mail Inquiry Management</h2>

              {/* <Button
                className="p-2 m-3 w-10 add-blog-button"
                variant="secondary"
                onClick={() => setShowAddModal(true)} // Show Add Inquiry Modal
              >
                Add New Inquiry +
              </Button> */}

              <DataTable columns={columns} data={mailInquiries} />
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Edit Inquiry Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formInquiryName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentInquiry.name || ""}
                onChange={(e) =>
                  setCurrentInquiry({ ...currentInquiry, name: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formInquiryEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={currentInquiry.email || ""}
                onChange={(e) =>
                  setCurrentInquiry({
                    ...currentInquiry,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formInquiryPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={currentInquiry.phone_number || ""}
                onChange={(e) =>
                  setCurrentInquiry({
                    ...currentInquiry,
                    phone_number: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formInquiryMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={currentInquiry.message || ""}
                onChange={(e) =>
                  setCurrentInquiry({
                    ...currentInquiry,
                    message: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* <Button variant="primary" onClick={handleSubmit} className="my-2">
              Save Changes
            </Button> */}
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Inquiry Modal */}
      <Modal show={showAddModal} onHide={handleAddClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewInquiryName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newInquiry.name}
                onChange={handleAddChange}
              />
            </Form.Group>

            <Form.Group controlId="formNewInquiryEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newInquiry.email}
                onChange={handleAddChange}
              />
            </Form.Group>

            <Form.Group controlId="formNewInquiryPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={newInquiry.phone_number}
                onChange={handleAddChange}
              />
            </Form.Group>

            <Form.Group controlId="formNewInquiryMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={newInquiry.message}
                onChange={handleAddChange}
              />
            </Form.Group>

            <Button variant="primary" onClick={handleAdd} className="my-2">
              Add Inquiry
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </>
  );
};

export default MailInquiresMgt;
