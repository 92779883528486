import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import directusClient from '../../api/directusClient';
import { useNavigate } from "react-router-dom";
import Sidebar from '../../components/common/Sidebar';

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("access_token");
        directusClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        const response = await directusClient.get('/users/me');
        setUser(response.data.data);
        setFormData({
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          email: response.data.data.email,
        });
        setError(null);
      } catch (err) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("email");

        delete directusClient.defaults.headers.common["Authorization"];
        window.location.href = "/";
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleEditClick = () => setIsEditing(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await directusClient.patch('/users/me', formData);
      setUser((prevUser) => ({ ...prevUser, ...formData }));
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const userRole = localStorage.getItem('user_role') || '';

  if (loading) return (
    <Container fluid className="text-center">
      <Row>
        <Col>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );

  if (error) return <Alert variant="danger">Error: {error}</Alert>;

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-3">
          <Sidebar role={userRole} />
        </Col>
        <Col md={10} className="p-3">
          <div className="p-4 border rounded bg-white shadow-sm">
            <h1 className="text-center mb-4">Profile</h1>
            {user && !isEditing ? (
              <div>
                <Row className="mb-2">
                  <Col md={4}><strong>First Name</strong></Col>
                  <Col md={8}>{user.first_name}</Col>
                </Row>
                <Row className="mb-2">
                  <Col md={4}><strong>Last Name</strong></Col>
                  <Col md={8}>{user.last_name}</Col>
                </Row>
                <Row className="mb-2">
                  <Col md={4}><strong>Email</strong></Col>
                  <Col md={8}>{user.email}</Col>
                </Row>
                <Button variant="primary" onClick={handleEditClick}>Edit</Button>
              </div>
            ) : (
              <Form onSubmit={handleSave}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setIsEditing(false)}
                  className="ms-2"
                >
                  Cancel
                </Button>
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
