import React, { useEffect } from "react";
import Carousel from "./Carousel";
import Book from "./Book";
import About from "./About";
import Blogs from "./Blogs";
import Exams from "./Exams";
import Sliders from "./Slider";
import Teams from "./Team";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WhoWeAre from "./WhoWeAre";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Carousel />
      <Exams />
      {/* <Book /> */}
      <WhoWeAre />
      {/* <About /> */}
      <Sliders />
      <Blogs />
      {/* <Teams /> */}
      <Footer />
    </>
  );
}
