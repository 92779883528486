import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import directusClient from "../../api/directusClient";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import styled from "styled-components";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { toast } from "react-toastify";
import { apiRequest } from "../../hooks/apiRequest";
import { STUDENT_API_ENDPOINT, USER_API_ENDPOINT } from "../../api/endpoints";
import { ROLE_STUDENT } from "../../api/roles";
import BookSpinner from "../../components/common/BookSpinner";

const LoginRow = styled(Row)`
  min-height: 650px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  background: url("/assets/img/register.jpg") no-repeat center center;
  background-size: cover;
  width: 50%;
  border-radius: 10px 0 0 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormCol = styled(Col)`
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoginTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 20px;
  text-align: center;
`;

const CustomButton = styled(Button)`
  background-color: #007bff;
  border: none;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    grade: "",
    school_name: "",
    address: "",
    gender: "",
    parent_name: "",
    email: "",
    contact_number:"",
    subject1: "",
    subject2: "",
    password: "",
    confirmPassword: "",
    role: ROLE_STUDENT,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!formData.first_name.trim())
      newErrors.first_name = "First Name is required.";
    if (!formData.last_name.trim())
      newErrors.last_name = "Last Name is required.";
    if (!formData.contact_number.trim())
      newErrors.contact_number = "Contact Number is required.";
    if (!formData.grade) newErrors.grade = "Please select a grade.";
    if (!formData.school_name.trim())
      newErrors.school_name = "School Name is required.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.gender) newErrors.gender = "Please select a gender.";
    if (!formData.parent_name.trim())
      newErrors.parent_name = "Parent Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email Address is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // Step 1: Create the Directus user
      const createdUser = await directusClient.post("/users", {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        role: ROLE_STUDENT,
        status: "invited", // Set status to 'active' or any required status
      });

      const loginResponse = await directusClient.post("/auth/login", {
        email: formData.email,
        password: formData.password,
      });
    
      const { access_token } = loginResponse.data.data;
      localStorage.setItem("access_token", access_token);
    
      // Step 2: Use the access token to fetch the authenticated user's details
      directusClient.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      const userResponse = await directusClient.get("/users/me");
      const user = userResponse.data.data;
    
      const directusUserId = user.id;
      console.log("Authenticated User:", user);

      localStorage.setItem("user_role", user.role);
      localStorage.setItem("email", user.email);

      // Step 2: Create the student record linked to the Directus user
      const studentPayload = {
        contact_number: formData.contact_number || null, // Provide contact number if available
        grade: formData.grade,
        school_name: formData.school_name,
        user: directusUserId, // Link to the created Directus user
        first_name: formData.first_name,
        last_name: formData.last_name,
        parent_name: formData.parent_name,
        subject1: formData.subject1,
        subject2: formData.subject2,
        gender: formData.gender,
        address: formData.address,
        status: "false", // Assuming 'true' means active
      };

      const studentResponse = await apiRequest(
        STUDENT_API_ENDPOINT,
        "POST",
        studentPayload
      );
      console.log("Student Created:", studentResponse.data);

      // Show success toast and navigate to login
      toast.success("Registration successful!");
      setLoading(false);
      navigate("/course-materials");
    } catch (error) {
      console.error("Error during registration:", error);
      setLoading(false);
      toast.error("Registration failed. Please try again.");
    }
  };

  if (loading) return <BookSpinner />;

  return (
    <>
      <Header />
      <div
        className="container-fluid page-header my-5 p-0"
        style={{
          background: `url(/assets/img/homebg.png)`,
          backgroundSize: "fit",
        }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5 center">
            <LoginRow>
              <ImageWrapper />
              <FormCol md={6}>
                <LoginTitle>
                  <img src="/assets/img/logo.png" alt="Logo" />
                  <h3>Register</h3>
                </LoginTitle>
                <Form onSubmit={handleRegister}>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.first_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.last_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Grade</Form.Label>
                    <Form.Control
                      as="select"
                      name="grade"
                      value={formData.grade}
                      onChange={handleInputChange}
                      isInvalid={!!errors.grade}
                    >
                      <option value="">Select Grade</option>
                      <option value="Grade 1">Grade 6</option>
                      <option value="Grade 2">Grade 7</option>
                      <option value="Grade 3">Grade 8</option>
                      <option value="Grade 3">Grade 9</option>
                      <option value="Grade 3">Grade 10</option>
                      <option value="Grade 3">Grade 11</option>
                      <option value="Grade 3">Grade 12</option>
                      <option value="Grade 3">Grade 13</option>
                      {/* Add more grades as needed */}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.grade}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">School Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="school_name"
                      value={formData.school_name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.school_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.school_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Gender</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      isInvalid={!!errors.gender}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Female">Other</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Parent Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_name"
                      value={formData.parent_name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.parent_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.parent_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_number"
                      value={formData.contact_number}
                      onChange={handleInputChange}
                      isInvalid={!!errors.contact_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contact_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Subject 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="subject1"
                      value={formData.subject1}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Subject 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="subject2"
                      value={formData.subject2}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label className="fw-bold">Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <CustomButton type="submit">Register</CustomButton>
                </Form>
              </FormCol>
            </LoginRow>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
