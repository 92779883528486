import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { apiRequest } from "../../hooks/apiRequest"; // Hook for API request
import { STUDENT_API_ENDPOINT } from "../../api/endpoints"; // API endpoint for students
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/common/Sidebar";
import DashboardCards from "./DashboardCards";
import { useNavigate } from "react-router-dom";
import BookSpinner from "../../components/common/BookSpinner";
import directusClient from "../../api/directusClient";

const Dashboard = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar visibility state
  const userRole = localStorage.getItem("user_role") || "";

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("access_token");
        directusClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        const response = await directusClient.get("/users/me");
      } catch (err) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("email");

        delete directusClient.defaults.headers.common["Authorization"];
        window.location.href = "/";
      }
    };
    fetchUserProfile();
  }, []);

  // Logout unauth user
  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(STUDENT_API_ENDPOINT);
        setStudents(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to load student data.");
        setLoading(false);
      }
    };
    fetchStudents();
  }, []);

  if (loading) return <BookSpinner />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="p-3 d-none d-md-block">
            <Sidebar role={userRole} />
          </Col>
          <Col
            md={2}
            className={`p-3 d-md-none ${sidebarOpen ? "d-block" : "d-none"}`}
          >
            <Sidebar role={userRole} />
          </Col>
          <Col md={10} className="p-3">
            <Card className="p-3">
              <div className="d-flex justify-content-between align-items-center">
                <h1>DASHBOARD</h1>
                <button
                  type="button"
                  className="navbar-toggler bg-light"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </Card>

            {/* Main Welcome Card */}
            <Card
              className="mt-3 p-4"
              style={{
                background: "#004085", // Blue background
                color: "white",
                borderRadius: "15px",
                position: "relative",
              }}
            >
              <Row className="align-items-center">
                <Col md={7}>
                  <h2>Welcome, Ashani Gamage Tuition</h2>
                  <p>
                    Easily manage student registrations, upload course
                    materials, and share related blogs. Streamline your teaching
                    experience with an intuitive dashboard designed to support
                    educators in providing quality learning resources and
                    updates..
                  </p>
                </Col>
                <Col md={5}>
                  <img
                    src={"/assets/img/ash.png"}
                    alt="Right Side Illustration"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                </Col>
              </Row>
            </Card>

            <DashboardCards />
          </Col>
        </Row>
      </Container>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </>
  );
};

export default Dashboard;
