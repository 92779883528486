import React, { useEffect } from "react";
import Heading from "../components/common/Heading";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Slider from "../components/home/Slider";
import PopularCourses from "../components/home/PopularCourses";
import WhoWeAre from "../components/home/WhoWeAre";
import QualityEdu from "../components/home/QualityEdu";
import StatCards from "../components/home/StatCards";
export default function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Heading
        heading="About"
        title="Home"
        subtitle="About"
        img={"/assets/img/aboutbg.jpg"}
        description={
"With over 20 years of experience, we specialize in GCSE and A-level tutoring for Science and Maths, delivering excellent results. Serving students across private, grammar, and non-selective schools in Buckinghamshire and Hertfordshire, we tailor our hands-on approach to suit diverse learning needs. From innovative techniques for children with learning difficulties to traditional methods, our adaptable teaching ensures every child thrives."        }
      />
      <StatCards />
      <WhoWeAre />
      <QualityEdu />
      <Slider />
      {/* <Team /> */}
      <PopularCourses />
      <Footer />
    </>
  );
}
