import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CommonHeading from "../components/common/CommonHeading";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import PopularCourses from "../components/home/PopularCourses";
import CourseDetails from "../components/home/CourseDetails";
import { courses } from "../components/data/Data"; // Assuming you've renamed `courseData` to `courses`

export default function Courses() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseCode = queryParams.get("courseCode");

  // Fallback for invalid courseCode
  const course = courses.find((course) => course.courseCode === courseCode);
  const courseName = course ? course.title : "Course Not Found";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div
        className="container-fluid page-header p-0"
        style={{ marginTop: "150px" }}
      >
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <CommonHeading
            heading="Best for you"
            title={courseName}
            subtitle="COURSE - "
          />
        </div>
        {/* Only show CourseDetails if courseCode is valid */}
        {course ? (
          <CourseDetails courseCode={courseCode} />
        ) : (
          <p>Course not found.</p>
        )}
        <PopularCourses />
        <Footer />{" "}
      </div>
    </>
  );
}
