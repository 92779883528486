import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Spinner,
} from "react-bootstrap";
import { apiRequest } from "../../hooks/apiRequest"; // Hook for API request
import { STUDENT_API_ENDPOINT } from "../../api/endpoints"; // API endpoint for students
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/common/Sidebar";
import DataTable from "../../components/common/DataTable";
import directusClient from "../../api/directusClient";
import { useNavigate } from "react-router-dom";
import BookSpinner from "../../components/common/BookSpinner";

const StuMgt = () => {
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("access_token");
        directusClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        const response = await directusClient.get("/users/me");
      } catch (err) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("email");

        delete directusClient.defaults.headers.common["Authorization"];
        window.location.href = "/";
      }
    };
    fetchUserProfile();
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal for editing student
  const [showAddModal, setShowAddModal] = useState(false); // Modal for adding new student
  const [currentStudent, setCurrentStudent] = useState({});
  const [newStudent, setNewStudent] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    grade: "",
    school_name: "",
    status: "true", // Default status is active
  });
  const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar visibility state
  const userRole = localStorage.getItem("user_role") || "";

  // Fetch students from the API
  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(STUDENT_API_ENDPOINT);
        setStudents(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to load student data.");
        setLoading(false);
      }
    };
    fetchStudents();
  }, []);

  // Toggle student status
  const handleToggle = async (id) => {
    showConfirmation("Are you sure you want to edit this?", async () => {
      const student = students.find((student) => student.id === id);
      const updatedStatus = student.status === "true" ? "false" : "true";

      try {
        await apiRequest(`${STUDENT_API_ENDPOINT}/${id}`, "PATCH", {
          status: updatedStatus,
        });

        await directusClient.patch(`/users/${student.user}`, {
          status: student.status === "true" ? "invited" : "active", // Set status to 'active' or any required status
        });

        setStudents(
          students.map((stu) =>
            stu.id === id ? { ...stu, status: updatedStatus } : stu
          )
        );
        toast.success("Status updated successfully.");
      } catch (error) {
        toast.error("Failed to update status.");
      }
    });
  };

  // Edit student details
  const handleEdit = (student) => {
    setCurrentStudent(student);
    setShowModal(true);
  };

  // Handle adding a new student + removed from this client no need to use this
  const handleAdd = async () => {
    try {
      const response = await apiRequest(
        STUDENT_API_ENDPOINT,
        "POST",
        newStudent
      );
      setStudents([...students, response.data]); // Add new student to the list
      setShowAddModal(false);
      toast.success("Student added successfully.");
    } catch (error) {
      toast.error("Failed to add student.");
    }
  };

  // Handle closing the modal
  const handleClose = () => setShowModal(false);
  const handleAddClose = () => setShowAddModal(false);

  // Handle changes in the new student form
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setNewStudent({ ...newStudent, [name]: value });
  };

  // Handle saving changes after editing a student
  const handleSubmit = async () => {
    setShowModal(false);
    showConfirmation("Are you sure you want to edit this?", async () => {
      try {
        setLoading(true);
        const response = await apiRequest(
          `${STUDENT_API_ENDPOINT}/${currentStudent.id}`,
          "PATCH",
          currentStudent
        );

        setStudents(
          students.map((stu) =>
            stu.id === currentStudent.id ? currentStudent : stu
          )
        );
        setShowModal(false);
        setLoading(false);
        toast.success("Student details updated successfully.");
      } catch (error) {
        setShowModal(true);
        setLoading(false);
        toast.error("Failed to update student.");
      }
    });
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false); // New state for confirmation modal
  const [confirmationCallback, setConfirmationCallback] = useState(null); // Store callback for confirmation
  const [submitMessage, setSubmitMessage] = useState("");

  const showConfirmation = (message, callback) => {
    setSubmitMessage(message);
    setConfirmationCallback(() => callback);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    if (confirmationCallback) confirmationCallback();
    setShowConfirmModal(false);
  };

  const handleCancelConfirm = () => {
    setShowModal(true);
    setShowConfirmModal(false);
  };

  // Columns for DataTable
  const columns = [
    {
      Header: "Date",
      accessor: "date_created",
      Cell: ({ value }) => {
        const formattedDate = value.replace('T', ' ').replace('.000Z', '');
        return formattedDate;
      },
    },
    { Header: "ID", accessor: "id" },
    { Header: "First Name", accessor: "first_name" },
    { Header: "Last Name", accessor: "last_name" },
    { Header: "Contact Number", accessor: "contact_number" },
    { Header: "Grade", accessor: "grade" },
    { Header: "School Name", accessor: "school_name" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Form.Switch
          checked={row.original.status === "true"}
          onChange={() => handleToggle(row.original.id)}
          label={row.original.status === "true" ? "Active" : "Inactive"}
        />
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Button variant="primary" onClick={() => handleEdit(row.original)}>
          Edit
        </Button>
      ),
    },
  ];

  if (loading) return <BookSpinner />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="p-3 d-none d-md-block">
            <Sidebar role={userRole} />
          </Col>
          <Col
            md={2}
            className={`p-3 d-md-none ${sidebarOpen ? "d-block" : "d-none"}`}
          >
            <Sidebar role={userRole} />
          </Col>
          <Col md={10} className="p-3">
            <Card className="p-3">
              <div className="d-flex justify-content-between align-items-center">
                <h1>STUDENT</h1>
                <button
                  type="button"
                  className="navbar-toggler bg-light"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </Card>

            <Card className="text-center mt-3">
              <h2 className="m-3">STUDENT MANAGEMENT</h2>

              <DataTable columns={columns} data={students} />
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Edit Student Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formStudentFirstName">
              {/* <Form.Label>First Name</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={currentStudent.first_name || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    first_name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={currentStudent.last_name || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    last_name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentContact">
              {/* <Form.Label>Contact Number</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>
                Contact Number
              </Form.Label>
              <Form.Control
                type="text"
                name="contact_number"
                value={currentStudent.contact_number || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    contact_number: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentGrade">
              {/* <Form.Label>Grade</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>Grade</Form.Label>
              <Form.Control
                as="select"
                name="grade"
                value={currentStudent.grade || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    grade: e.target.value,
                  })
                }
              >
                <option value="">Select Grade</option>
                <option value="Grade 1">Grade 1</option>
                <option value="Grade 2">Grade 2</option>
                <option value="Grade 3">Grade 3</option>
                {/* Add more grades as needed */}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formStudentSchool">
              {/* <Form.Label>School Name</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>
                School Name
              </Form.Label>
              <Form.Control
                type="text"
                name="school_name"
                value={currentStudent.school_name || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    school_name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentAddress">
              {/* <Form.Label>Address</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={currentStudent.address || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    address: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentGender">
              {/* <Form.Label>Gender</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={currentStudent.gender || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    gender: e.target.value,
                  })
                }
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formStudentParentName">
              {/* <Form.Label>Parent Name</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>
                Parent Name
              </Form.Label>
              <Form.Control
                type="text"
                name="parent_name"
                value={currentStudent.parent_name || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    parent_name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentSubject1">
              {/* <Form.Label>Subject 1</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>Subject 1</Form.Label>
              <Form.Control
                type="text"
                name="subject1"
                value={currentStudent.subject1 || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    subject1: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formStudentSubject2">
              {/* <Form.Label>Subject 2</Form.Label> */}
              <Form.Label style={{ fontWeight: "bold" }}>Subject 2</Form.Label>
              <Form.Control
                type="text"
                name="subject2"
                value={currentStudent.subject2 || ""}
                onChange={(e) =>
                  setCurrentStudent({
                    ...currentStudent,
                    subject2: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Button variant="primary" onClick={handleSubmit} className="my-2">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Toast Container for notifications */}
      <ToastContainer />

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{submitMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StuMgt;
