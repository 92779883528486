import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import directusClient from "../../api/directusClient";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { ROLE_ADMIN, ROLE_STUDENT } from "../../api/roles";
import styled from "styled-components";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { toast } from "react-toastify";
import BookSpinner from "../../components/common/BookSpinner";

const LoginRow = styled(Row)`
  min-height: 550px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  background: url("/assets/img/loginbg.png") no-repeat center center;
  background-size: cover;
  width: 50%;
  border-radius: 10px 0 0 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormCol = styled(Col)`
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoginTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 20px;
  text-align: center;
`;

const CustomButton = styled(Button)`
  background-color: #007bff;
  border: none;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
        try {
      const response = await directusClient.post("/auth/login", { email, password });
      const { access_token } = response.data.data;

      localStorage.setItem("access_token", access_token);
      directusClient.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

      const userResponse = await directusClient.get("/users/me");
      const user = userResponse.data.data;

      localStorage.setItem("user_role", user.role);
      localStorage.setItem("email", user.email);

      const userRole = localStorage.getItem("user_role") || "";
      setLoading(false);
      if (userRole === ROLE_ADMIN) {
        navigate("/dashboard");
      } else if (userRole === ROLE_STUDENT) {
        navigate("/course-materials");
      }
    } catch (err) {
      setLoading(false);      toast.error("Login failed! Please check your credentials and try again.");
      setError("Login failed. Please check your credentials and try again or inform admin to activate account");
    }
  };
  if (loading) return <BookSpinner />;
  return (
    <>
      <Header />
      <div className="container-fluid page-header my-5 p-0" style={{ background: `url(/assets/img/homebg.png)`, backgroundSize: "fit" }}>
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5 center">
            <LoginRow>
              <ImageWrapper />
              <FormCol md={6}>
                <LoginTitle>
                  <img src="/assets/img/logo.png" alt="Logo" />
                  <h3>Login</h3>
                </LoginTitle>
                <Form onSubmit={handleLogin}>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      required
                    />
                  </Form.Group>
                  <CustomButton type="submit" className="w-100 mb-2">
                    Login
                  </CustomButton>
                  {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                </Form>
              </FormCol>
            </LoginRow>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
